<template>
  <section class="section help-page">
    <div class="container">
      <div class="content">
        <h1>{{ $t("help.title")}}</h1>
        <h2 v-html="$t('help.chapter1')"></h2>
        <p class="is-size-5" v-html="$t('help.copy1')"></p>
        <h2>{{ $t("help.chapter2") }}</h2>
        <p class="is-size-5" v-html="$t('help.copy2')"></p>
        <h2>{{ $t("help.chapter3") }}</h2>
        <p class="is-size-5" v-html="$t('help.copy3')"></p>
      </div>
    </div>
  </section>
</template>

<script>
import fn from '../../services/GlobalService'

export default {
  name: 'help-page',
  data () {
    return {}
  },
  methods: {},
  mounted () {}
}
</script>

<style lang="sass">
.help-page
  background-color: $white

  .content

    br
      line-height: 2rem
</style>
